import Image from "next/image";
import Link from "next/link";

import logo from "../../public/images/logo/logo.png";

import Nav from "./Nav";
import { useAppContext } from "@/context/Context";
import { useSelector } from "react-redux";

const MobileMenu = () => {
  const { mobile, setMobile } = useAppContext();
  const { details } = useSelector((state) => state.CompanyReducer);
  const { web_details } = useSelector((state) => state.WebsiteReducer);
  console.log(web_details, "web11");

  return (
    <>
      <div className={`popup-mobile-menu ${mobile ? "" : "active"}`}>
        <div className="inner-wrapper">
          <div className="inner-top">
            <div className="content">
              <div className="logo">
                <Link href="/">
                  <Image
                    src={details?.org_logo !== null ? details?.org_logo : ""}
                    width={152}
                    height={70}
                    alt={details?.org_name}
                  />
                </Link>
              </div>
              <div className="rbt-btn-close">
                <button
                  className="close-button rbt-round-btn"
                  onClick={() => setMobile(!mobile)}
                >
                  <i className="feather-x"></i>
                </button>
              </div>
            </div>
            <p className="description">
              {details?.org_name} is a educational site. You can learn course
              from here.
            </p>
            <ul className="navbar-top-left rbt-information-list justify-content-start">
              <li>
                <Link href="mailto:hello@example.com">
                  <i className="feather-mail"></i>
                  {web_details?.contact_email}
                </Link>
              </li>
              <li>
                <Link href="#">
                  <i className="feather-phone"></i>
                  {web_details?.contact_phone}
                </Link>
              </li>
            </ul>
          </div>

          <Nav />

          <div className="mobile-menu-bottom">
            {/* <div className="rbt-btn-wrapper mb--20">
              <Link
                className="rbt-btn btn-border-gradient radius-round btn-sm hover-transform-none w-100 justify-content-center text-center"
                href="#"
              >
                <span>Enroll Now</span>
              </Link>
            </div> */}

            <div className="social-share-wrapper">
              <span className="rbt-short-title d-block">Find With Us</span>
              <ul className="social-icon social-default transparent-with-border justify-content-start mt--20">
                {/* <li>
                  <Link href="https://www.facebook.com/">
                    <i className="feather-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link href="https://www.twitter.com">
                    <i className="feather-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link href="https://www.instagram.com/">
                    <i className="feather-instagram"></i>
                  </Link>
                </li>
                <li>
                  <Link href="https://www.linkdin.com/">
                    <i className="feather-linkedin"></i>
                  </Link>
                </li> */}
                {/* <li>
                  <Link
                    href={
                      web_details?.sm_facebook ? web_details?.sm_facebook : "/"
                    }
                  >
                    <i className="feather-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    href={
                      web_details?.sm_twitter ? web_details?.sm_twitter : ""
                    }
                  >
                    <i className="feather-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    href={
                      web_details?.sm_twitter ? web_details?.sm_twitter : ""
                    }
                  >
                    <i className="feather-linkedin"></i>
                  </Link>
                </li> */}
                <li>
                  <Link
                    target="_blank"
                    href="https://www.linkedin.com/in/sikho-kamana-4a30792b2?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3By9vxacQsREWIUP5buzprdg%3D%3D"
                  >
                    <i className="feather-linkedin"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.instagram.com/sikhokamana_/"
                    target="_blank"
                  >
                    <i className="feather-instagram"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
