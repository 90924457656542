import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import Link from "next/link";
import dynamic from "next/dynamic";
import styles from "@/styles/Login.module.css";

import { useDispatch, useSelector } from "react-redux";
import { useAppContext } from "@/context/Context";
import { deleteProduct } from "@/redux/action/CartAction";
import axios from "axios";
import Swal from "sweetalert2";
import CourseDetails from "../../../data/course-details/courseData.json";
import { loadData } from "@/utils/localStorage";
import MyModal from "@/components/Modal/MyModal";

const Cart = () => {
  const [open, setOpen] = useState(false);
  const [screen, setScreen] = useState(1);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [regId, setRegId] = useState("");
  const [newState, setNewState] = useState("");
  const [newDistrict, setNewDistrict] = useState("");
  const [proff, setProff] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [value, setValue] = useState(0);
  const [stateOption, setStateOption] = useState([]);
  const [districtOption, setDistrictOption] = useState([]);
  const [step1Details, setStep1Details] = useState({
    // aadhaar_no: "545546665",
    // // aadhaar_no: "",
    // pan: "",
    // address: "dhsj kjskhhsg kjhdhsb",
    // // address: "",
    // mobile: "8154584845",
    // first_name: "ggdh hsgdj",
    // doner_code: "SBA00015223",
  });
  const [amount, setAmount] = useState(0);
  const [pin, setPin] = useState(false);
  const [check, setCheck] = useState(false);
  // const [orderDetails, setOrderDetails] = useState({});
  const [paymentID, setPaymentID] = useState("");
  const [step2Details, setStep2Details] = useState({});
  const [donorID, setDonorID] = useState("");
  const [orderId, setOrderId] = useState("");
  const [selectCourse, setSelectCourse] = useState({});
  const [failureModal, setFailureModal] = useState(false);
  const candid = useSelector((state) => state);
  console.log(candid);
  const router = useRouter();
  const path = typeof window !== "undefined" ? window.location.pathname : "";
  const dispatch = useDispatch();
  const { cart, total_amount } = useSelector((state) => state.CartReducer);
  const [myData, setMyData] = useState(null);
  const user = useSelector((state) => state.LoginReducer.details);
  const website = useSelector((state) => state.WebsiteReducer.web_details);
  console.log(user, "it113");
  const { cartToggle, setCart } = useAppContext();
  const defaultImg = CourseDetails?.courseDetails[0]["courseImg"];

  useEffect(() => {
    dispatch({ type: "COUNT_CART_TOTALS" });
    localStorage.setItem("hiStudy", JSON.stringify(cart));

    if (path === "/cart") {
      setCart(true);
    }
  }, [cart, path]);
  const isAuth = useSelector((state) => state.LoginReducer.isAuth);
  console.log("is", isAuth, cart);

  const [interested, setInterested] = useState([]);

  const fetchInterested = async () => {
    axios
      .get(
        `${process.env.NEXT_PUBLIC_BACKEND_GENERIC_URL}/skilling/all-interested-course`,
        {
          params: {
            candidate_id: myData?.registration_id,
          },
        }
      )
      .then((res) => {
        setInterested(res.data.data);
      })
      .catch((err) => {
        // setCart(false);
        console.log(err);
        // Swal.fire({
        //   //position: "top",
        //   icon: "error",
        //   title: `${
        //     err.response?.data.message
        //       ? err.response?.data.message
        //       : "Something went wrong"
        //   }`,
        //   // text: res.response.data["User Message"],
        //   showConfirmButton: true,
        //   timer: 3000,
        // });
      });
  };

  const deleteInterestCourse = async (id) => {
    axios
      .patch(
        `${process.env.NEXT_PUBLIC_BACKEND_GENERIC_URL}/skilling/course-interested-removed`,
        {
          course_id: id,
          candid: myData?.registration_id,
        }
      )
      .then((res) => {
        setCart(!cartToggle);
        Swal.fire({
          //position: "top",
          icon: "success",
          title: `${res.data?.message}`,
          // text: res.response.data["User Message"],
          showConfirmButton: true,
          timer: 3000,
        });
      })
      .catch((err) => {
        setCart(!cartToggle);
        // sessionStorage.setItem("cart", false);
        Swal.fire({
          //position: "top",
          icon: "error",
          title: `${
            err.response?.data.message
              ? err.response?.data.message
              : "Something went wrong"
          }`,
          // text: res.response.data["User Message"],
          showConfirmButton: true,
          timer: 3000,
        }).then(() => {
          // setCart(!cartToggle);
          //  fetchInte rested();
          sessionStorage.setItem("cart", true);
        });
      });
  };
  useEffect(() => {
    myData !== null && fetchInterested();
    console.log(interested, "it111");
  }, [myData, cart, cartToggle]);
  useEffect(() => {
    setMyData(loadData("user"));
  }, []);

  const addToCart = async (course_id) => {
    await axios
      .post(
        `${process.env.NEXT_PUBLIC_BACKEND_GENERIC_URL}/skilling/add-to-cart`,
        {
          candid: myData.registration_id,
          course_id: course_id,
        }
      )
      .then((res) => {
        setCart(!cartToggle);
        console.log(res.data);
        Swal.fire({
          //position: "top",
          icon: "success",
          title: `${res.data.message}`,
          // text: res.response.data["User Message"],
          showConfirmButton: true,
          timer: 3000,
        }).then(() => {
          // setCart(!cartToggle);
          //  fetchInterested();
          router.push("/cart");

          sessionStorage.setItem("cart", true);
        });
      })
      .catch((err, res) => {
        setCart(!cartToggle);
        // sessionStorage.setItem("cart", false);
        Swal.fire({
          //position: "top",
          icon: "error",
          title: `${
            err.response?.data.message
              ? err.response?.data.message
              : "Something went wrong"
          }`,
          // text: res.response.data["User Message"],
          showConfirmButton: true,
          timer: 3000,
        }).then(() => {
          // setCart(!cartToggle);
          //  fetchInte rested();
          sessionStorage.setItem("cart", true);
        });
      });
  };
  return (
    <>
      <div className={`${!cartToggle ? "cart-sidenav-menu-active" : ""}`}>
        <div
          className={`rbt-cart-side-menu ${
            !cartToggle ? "side-menu-active" : ""
          }`}
        >
          <div className="inner-wrapper">
            <div className="inner-top">
              <div className="content">
                <div className="title">
                  <h4 className="title mb--0">Your interested courses</h4>
                </div>
                <div className="rbt-btn-close" id="btn_sideNavClose">
                  <button
                    className="minicart-close-button rbt-round-btn"
                    onClick={() => setCart(!cartToggle)}
                  >
                    <i className="feather-x"></i>
                  </button>
                </div>
              </div>
            </div>
            <nav className="side-nav w-100">
              <div className="rbt-minicart-wrapper">
                {/* {cart &&
                  cart.map((data, index) => (
                    <li className="minicart-item" key={index}>
                      <div className="thumbnail">
                        <Link
                          href={
                            data.product.title
                              ? `/event-details/${data.id}`
                              : `/course-details/${data.id}`
                          }
                        >
                          <Image
                            src={
                              data.product.courseImg || data.product.eventImg
                            }
                            width={80}
                            height={64}
                            alt="Product Images"
                          />
                        </Link>
                      </div>
                      <div className="product-content">
                        <h6 className="title">
                          <Link
                            href={
                              data.product.title
                                ? `/event-details/${data.id}`
                                : `/course-details/${data.id}`
                            }
                          >
                            {data.product.courseTitle || data.product.title}
                          </Link>
                        </h6>

                        <span className="quantity">
                          {data.amount} *
                          <span className="price">₹{data.product.price}</span>
                        </span>
                      </div>
                      <div className="close-btn">
                        <button
                          className="rbt-round-btn"
                          onClick={() => dispatch(deleteProduct(data.id))}
                        >
                          <i className="feather-x"></i>
                        </button>
                      </div>
                    </li>
                  ))} */}
                {cart &&
                  interested.map((data, index) => (
                    <li className="minicart-item" key={index}>
                      <div className="thumbnail">
                        {/* <Link
                          href={
                            data.course_title
                              ? `/event-details/${data.course_id}`
                              : `/course-details/${data.course_id}`
                          }
                        > */}
                        <Image
                          src={data.course_img || defaultImg}
                          // src={defaultImg}
                          width={80}
                          height={64}
                          alt="Course"
                        />
                        {/* </Link> */}
                      </div>
                      <div className="product-content">
                        <h5 className="title">
                          {/* <Link
                            href={
                              data.title
                                ? `/event-details/${data.id}`
                                : `/course-details/${data.id}`
                            }
                          > */}
                          {data.name}
                          {/* </Link> */}
                        </h5>

                        <span className="quantity">
                          {/* {data.amount} * */}
                          {website?.payment_optin == 1 && (
                            <div classname="grid">
                              <span className="price">₹{data.fees}</span>
                              <div
                                onClick={() => {
                                  addToCart(data.course_id);
                                }}
                              >
                                <Link
                                  href=""
                                  // href="/cart"
                                  // className="rbt-btn btn-gradient rbt-switch-btn rbt-switch-y text-center"
                                  // style={{ padding: "5px", height:"50%" }}
                                >
                                  <span
                                    data-text="Add to Cart"
                                    className={styles.greenText}
                                  >
                                    Add to Cart
                                  </span>
                                </Link>
                              </div>
                            </div>
                          )}
                        </span>
                      </div>
                      <div className="close-btn">
                        <button
                          className="rbt-round-btn"
                          onClick={() => {
                            dispatch(deleteProduct(data.course_id));
                            deleteInterestCourse(data.course_id);
                          }}
                        >
                          <i className="feather-x"></i>
                        </button>
                      </div>
                    </li>
                  ))}
              </div>
            </nav>

            <div className="rbt-minicart-footer">
              <hr className="mb--0" />
              {/* <div className="rbt-cart-subttotal">
                <p className="subtotal">
                  <strong>Subtotal:</strong>
                </p>
                <p className="price">₹{total_amount}</p>
              </div> */}
              {/* <hr className="mb--0" /> */}
              <div className="rbt-minicart-bottom mt--20">
                <div className="view-cart-btn">
                  <Link
                    className="rbt-btn btn-border icon-hover w-100 text-center"
                    href="/cart"
                  >
                    {/* <span className="btn-text">View Cart</span> */}
                    <span className="btn-text">View Cart</span>
                    <span className="btn-icon">
                      <i className="feather-arrow-right"></i>
                    </span>
                  </Link>
                </div>
                {cart.length !== 0 && (
                  <div>
                    {myData !== null && isAuth ? (
                      <div className="checkout-btn mt--20">
                        <Link
                          className="rbt-btn btn-border icon-hover w-100 d-block text-center"
                          href="#"
                          // onClick={()=>{openPayModal({
                          //   ...options,
                          //   order_id: "",
                          //   amount: parseInt(data.fees),
                          // },); setAmount(data.fees)}}
                          onClick={() => {
                            setAmount(parseInt(data.fees));
                            setSelectCourse({
                              course_id: data.course_id,
                              amount: parseInt(data.fees),
                            });
                            console.log(myData, isAuth, "is112");
                          }}
                        >
                          <span className="btn-text">Buy Now</span>
                          <span className="btn-icon">
                            <i className="feather-arrow-right"></i>
                          </span>
                        </Link>
                      </div>
                    ) : (
                      <div className="checkout-btn mt--20">
                        <div
                          className="rbt-btn btn-border icon-hover w-100 d-block text-center"
                          onClick={() => {
                            console.log(myData, isAuth, "is11");
                            handleOpen();
                          }}
                        >
                          <span className="btn-text">Buy Now</span>
                          <span className="btn-icon">
                            <i className="feather-arrow-right"></i>
                          </span>
                        </div>

                        <MyModal open={open} handleClose={handleClose} />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Link
          className="close_side_menu"
          href="#"
          onClick={() => setCart(!cartToggle)}
        ></Link>
      </div>
    </>
  );
};

export default dynamic(() => Promise.resolve(Cart), { ssr: false });
